@tailwind base;
@tailwind components;
@tailwind utilities;



/* This removes focus border (outline) around text/input boxes (Chrome) */
textarea:focus, input:focus{
    outline: none;
}

button:focus {
    outline: none;
    /* border-radius:  0.375rem; */
}

button:active, input[type=submit]:active, .lockIcon:active  {
     transform: scale(.5);
     transition: all .6s ease-out;  
}
    

/* .bbox {
    position: absolute;
    border: 1px solid red;
} */